import React from 'react'
import { useTranslation } from 'react-i18next'

import Layout from 'components/Layout'

const Error = () => {
  const { t, ready } = useTranslation('errorPage')

  return (
    <Layout headerBackgroundColor={'#47AFC9'}>
      {!ready ? null : (
        <>
          <h1>{t('notFoundOne')}</h1>
          <p>{t('notFoundTwo')}</p>
        </>
      )}
    </Layout>
  )
}

export default Error
